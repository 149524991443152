import React, { type FC, useState, useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { useRouteDataRef } from '@confluence/route-manager';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

import { useMenuItems, type menuId, type platformCustomizationArray } from './useMenuItems';
import { GlobalItemLoadables } from './GlobalItems/GlobalItemLoadables';
import { MoreMenuItem } from './MoreMenuItem';

type GlobalNavigationComponentProps = {
	platformCustomizationArray?: platformCustomizationArray;
};

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireMoreMenuClickedAnalytics,
);

export const GlobalNavigationComponent: FC<GlobalNavigationComponentProps> = ({
	platformCustomizationArray,
}) => {
	const { accessStatus } = useSessionData();
	const [peekingId, setPeekingId] = useState<menuId | undefined>(undefined);
	const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const moreMenuOnClickCallback = useCallback(
		(menuId: menuId) => {
			setIsMoreMenuOpen(!isMoreMenuOpen);
			void fireClickAnalytics(menuId);
		},
		[fireClickAnalytics, isMoreMenuOpen],
	);

	const isPermitted = !(
		accessStatus === AccessStatus.ANONYMOUS_ACCESS ||
		accessStatus === AccessStatus.UNLICENSED_AUTHENTICATED_ACCESS
	);
	const { globalMenuItems, moreMenuItems } = useMenuItems(isPermitted, platformCustomizationArray);

	return (
		<ErrorBoundary
			attribution={Attribution.DISCO}
			attributes={{
				errorBoundaryId: 'sideNavigation-globalNavigation',
			}}
		>
			{globalMenuItems.map((result) => {
				const Component = GlobalItemLoadables[result.menuId];
				return Component && <Component key={result.menuId} />;
			})}
			{moreMenuItems.map((result) => {
				const Component = GlobalItemLoadables[result.menuId];
				return (
					Component && (
						<Component
							key={result.menuId}
							isHidden
							peekingId={peekingId}
							setPeekingId={setPeekingId}
						/>
					)
				);
			})}
			<MoreMenuItem
				moreMenuItems={moreMenuItems}
				isOpen={isMoreMenuOpen}
				onClick={moreMenuOnClickCallback}
				toggleMenu={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
				peekingId={peekingId}
			/>
		</ErrorBoundary>
	);
};
